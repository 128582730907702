/*-- Variables --*/
:root {
  //Themes colors
  --color-primary: #a17a48; // A medium brown, like birch bark
  --color-secondary: #d2b48c; // Tan, reminiscent of lighter parts of birch
  --color-secondary-alt: #fffaf0; // Off-white, similar to birch highlights
  --color-tertiary: #d2b48c; // Same tan as secondary
  --color-tertiary-2: #a17a48; // Same medium brown as primary
  --color-dark: #5e503f; // Dark brown, like the darker parts of birch
  --color-body: #9c8c78; // A softer brown, for general text and UI
  --color-heading: #5e503f; // Dark brown, for emphasis and headers
  --color-white: #ffffff; // White remains the same
  --color-shape: #f5f5f5; // Light gray, for backgrounds and shapes
  --color-shade: #e0e0d1; // Pale brownish-gray, for shading
  --color-border: #e0e0d1; // Same as shade, for borders

  // Typo Color
  --color-black: #000000; // Black remains the same
  --color-blackest: #0f0f11; // Very dark gray, almost black

  // Extra Color
  --color-extra01: #3a2c1a; // Muted brown
  --color-extra02: #726e65; // Darker grayish-brown
  --color-extra03: #fbfbfd; // Light off-white
  --color-extra04: #3a352e; // Dark brown, almost black
  --color-extra05: #4a443c; // Dark gray-brown
  --color-extra06: #dbf1c0; // Light green, complementary to browns
  --color-extra07: #c6ece8; // Soft blue, for contrast
  --color-extra08: #c3ecce; // Pale green, for accents

  // Notify Colors
  --color-success: #3eb75e; // Green, for success messages
  --color-danger: #ff0003; // Red, for danger or errors
  --color-warning: #ff8f3c; // Orange, for warnings
  --color-info: #1ba2db; // Blue, for informational alerts

  //Social icon colors
  --color-facebook: #3b5997; // Blue, standard Facebook color
  --color-twitter: #1ba1f2; // Blue, standard Twitter color
  --color-youtube: #ed4141; // Red, standard YouTube color
  --color-linkedin: #0077b5; // Blue, standard LinkedIn color
  --color-pinterest: #e60022; // Red, standard Pinterest color
  --color-instagram: #c231a1; // Purple, standard Instagram color
  --color-vimeo: #00adef; // Blue, standard Vimeo color
  --color-twitch: #6441a3; // Purple, standard Twitch color
  --color-discord: #7289da; // Blue, standard Discord color

  //Border-radius
  --border-width: 2px;
  --radius-small: 5px;
  --radius: 10px;
  --radius-big: 16px;

  //Font weight
  --p-light: 300;
  --p-regular: 400;
  --p-medium: 500;
  --p-semi-bold: 600;
  --p-bold: 700;
  --p-extra-bold: 800;
  --p-black: 900;

  //Shadows
  --shadow-primary: 0px 8px 30px rgba(0, 0, 0, 0.04);
  --shadow-light: 1px 1px 6px rgba(0, 0, 0, 0.25);
  --shadow-lighter: 4px 4px 20px 20px rgba(0, 0, 0, 0.01);

  //transition easing
  --transition: 0.3s;
  --transition-transform: transform 0.65s cubic-bezier(0.23, 1, 0.32, 1);

  //Font Family
  --font-primary: 'Urbanist', sans-serif;
  --font-secondary: 'Urbanist', sans-serif;
  --font-awesome: 'Font Awesome 5 Pro';

  //Fonts Size
  --font-size-b1: 18px;
  --font-size-b2: 16px;
  --font-size-b3: 14px;
  --font-size-b4: 12px;

  //Line Height
  --line-height-b1: 1.67; //28px
  --line-height-b2: 1.63; //26px
  --line-height-b3: 1.6; //22px
  --line-height-b4: 1.3; //16px

  // Heading Font
  --h1: 72px; //90px 900 1.25
  --h2: 68px; //84px 900 1.24
  --h3: 40px; // 56px 900 1.4
  --h4: 32px; //44px 700  1.38
  --h5: 24px; //36px 1.5
  --h6: 20px; //20px 1.25

  --h1-lineHeight: 1.25;
  --h2-lineHeight: 1.24;
  --h3-lineHeight: 1.4;
  --h4-lineHeight: 1.38;
  --h5-lineHeight: 1.5;
  --h6-lineHeight: 1.6;
}

// Layouts Variation
$custom-lg-device-seven: 'only screen and (min-width: 1200px)';
$custom-lg-device-three: 'only screen and (min-width: 1750px)';
$custom-lg-device-four: 'only screen and (min-width: 1800px)';
$custom-lg-device-five: 'only screen and (min-width: 1900px)';
$custom-lg-device-two: 'only screen and (min-width: 1401px) and (max-width: 1750px)';
$custom-lg-device-six: 'only screen and (min-width: 1401px) and (max-width: 1650px)';
$custom-lg-device-eight: 'only screen and (min-width: 1200px) and (max-width: 1450px)';
$extra-device: 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$custom-laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1400px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout: 'only screen and (min-width: 768px) and (max-width: 991px)';
$custom-lg-device: 'only screen and (max-width: 1650px)';
$smlg-device: 'only screen and (max-width: 1199px)';
$small-tablet: 'only screen and (max-width: 991px)';
$sm-layout: 'only screen and (max-width: 767px)';
$large-mobile: 'only screen and (max-width: 575px)';
$small-mobile: 'only screen and (max-width: 479px)';

/*-----------------------
 * Banner Style One
------------------------*/

.height-850 {
  min-height: 850px;
  width: 100%;
  padding: 240px 0 190px;
  @media #{$sm-layout} {
    min-height: auto;
    width: 100%;
    padding: 250px 0;
  }
}

.height-940 {
  min-height: 940px;
  width: 100%;
  @media #{$sm-layout} {
    min-height: auto;
    width: 100%;
    padding: 250px 0;
  }
}

/*-------------------------
    Banner Style Four  
--------------------------*/

.banner-style-4 {
  background-color: #f0ece2;
  // background-image: url(../../images/bg/home-four-banner.jpg);
  position: relative;
  z-index: 1;
  overflow: hidden;
  .home-four-banner-wrapper {
    min-height: 870px;
    @media #{$smlg-device} {
      min-height: auto;
      width: 100%;
      width: 100%;
      padding: 120px 0 100px;
    }
    @media #{$small-tablet} {
      padding: 150px 0 150px;
    }
  }
  .banner-image {
    position: relative;
    padding: 35px;
    left: 115px;
    @media #{$lg-layout} {
      padding: 25px;
      left: 10px;
      transform: scale(0.9);
    }
    @media #{$md-layout} {
      left: 0;
      width: 74%;
      margin: 0 auto;
    }
    @media #{$sm-layout} {
      left: 0;
      margin: 0 auto;
      padding: 30px;
    }
    @media #{$large-mobile} {
      padding: 20px;
    }
    .thumbnail {
      img {
        width: 500px;
        max-height: 500px;
        border-radius: 100%;
        object-fit: cover;
      }
    }
    .round-images {
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.5s;
    }
    .learner-badge {
      background: #ffffff;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
      padding: 10px;
      width: 160px;
      height: 160px;
      border-radius: 100%;
      position: absolute;
      left: -80px;
      top: 25%;
      @media #{$large-mobile} {
        display: none;
      }
      .badge-inner {
        background: #ffa41b;
        border-radius: 100%;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        span {
          display: block;
          color: #ffffff;
          font-weight: 600;

          &.viewer {
            font-weight: 800;
            font-size: 32px;
            line-height: 44px;
          }
        }
      }
    }
  }

  .content {
    .pre-title {
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 16px;
      line-height: 26px;
      color: var(--color-primary);
      display: block;
      margin-bottom: 5px;
    }
    .title {
      font-weight: 800;
      font-size: 72px;
      line-height: 1.25;
      margin-bottom: 25px;
      @media #{$lg-layout} {
        font-size: 50px;
      }
      @media #{$md-layout} {
        font-size: 40px;
      }
      @media #{$sm-layout} {
        font-size: 40px;
      }
    }

    .description {
      margin-bottom: 60px;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      @media #{$md-layout} {
        margin-bottom: 30px;
      }
      @media #{$sm-layout} {
        margin-bottom: 30px;
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  .shape-image {
    position: absolute;
    z-index: -1;

    &.shape-image-1 {
      left: -110px;
      top: -50px;
    }

    &.shape-image-2 {
      right: 48%;
      top: -32px;
    }

    &.shape-image-3 {
      right: -210px;
      top: 10px;
    }

    &.shape-image-4 {
      right: 360px;
      bottom: -90px;
    }

    &.shape-image-5 {
      left: 463px;
      bottom: 270px;
    }

    &.shape-image-6 {
      left: 150px;
      bottom: -100px;
    }
    &.shape-image-left {
      left: 0;
      bottom: 8px;
    }
    &.shape-image-right {
      right: 0;
      bottom: 8px;
    }
  }

  .social-text-share {
    @extend %liststyle;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -175px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    @media #{$laptop-device} {
      left: -105px;
    }
    @media #{$custom-laptop-device} {
      left: -70px;
    }
    @media #{$smlg-device} {
      position: absolute;
      top: inherit;
      transform: none;
      left: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      bottom: -40px;
      margin: 0 -20px;
    }
    @media #{$small-tablet} {
      bottom: -65px;
    }

    li {
      margin: 0;
      a {
        display: inline-block;
        transform: rotate(-90deg);
        font-weight: 500;
        font-size: 21px;
        line-height: 22px;
        margin: 20px 0;
        position: relative;
        @media #{$smlg-device} {
          transform: none;
          margin: 0px 20px;
        }
        &::before {
          position: absolute;
          content: '';
          background: var(--color-primary);
          bottom: 0;
          width: 0;
          opacity: 0;
          left: 0;
          transition: 0.4s;
          height: 2px;
        }
        &:hover {
          &::before {
            width: 100%;
            opacity: 1;
          }
        }
      }
    }
  }
}

/*-----------------------
    Shop Style  
------------------------*/

.product {
  overflow: hidden;
  .inner {
    text-align: center;
    .thumbnail {
      position: relative;
      a {
        display: block;
        overflow: hidden;
        border-radius: 5px;
        img {
          width: 100%;
          border-radius: 5px;
          transition: 0.4s;
          overflow: hidden;
          &.w-100-thumb {
            display: flex;
          }
        }
      }
      .product-hover-info {
        position: absolute;
        bottom: 0;
        left: 0;
        background: var(--color-primary);
        width: 100%;
        border-radius: 0px 0px 5px 5px;
        transition: 0.4s;
        opacity: 0;
        height: 0;
        z-index: 1;
        ul {
          @extend %liststyle;
          display: flex;
          li {
            margin: 0;
            flex-basis: 50%;
            padding: 7px;
            position: relative;
            a {
              font-weight: 600;
              font-size: 16px;
              line-height: 26px;
              color: var(--color-white);
            }
            & + li {
              &::after {
                position: absolute;
                content: '';
                background: rgba(255, 255, 255, 0.2);
                width: 1px;
                height: 100%;
                left: 0;
                top: 0;
              }
            }
            &.product-each-item-content-inner {
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
            }
            &.old-price {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 16px;
            }
          }
        }
      }
    }
    .content {
      padding-top: 20px;
      .product-author {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        display: block;
        margin-bottom: 5px;
      }
      .title {
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 5px;
      }
      .price-list {
        &.price-style-03 {
          margin: -8px;
          .price {
            margin: 8px;
          }
          .current-price {
            font-size: 18px;
            line-height: 28px;
            color: var(--color-primary);
          }
          .old-price {
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
          }
        }
      }
    }
  }

  &:hover {
    .inner {
      .thumbnail {
        a {
          img {
            transform: scale(1.1);
          }
        }

        .product-hover-info {
          opacity: 1;
          height: 40px;
        }
      }
    }
  }
}
